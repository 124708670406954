import { Controller } from "@hotwired/stimulus"
import { end } from "@popperjs/core"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ["activitiesList", "menuContainer", "infosList"]
  connect() {
    console.log(window.innerWidth)
    console.log(window.matchMedia("(max-width: 1100px)").matches)
    window.innerWidth >= 1100 ? this.menuContainerTarget.classList.remove("d-none") : "";
  }

  // Closes dropdowns and burger menus when clicking anywhere on the screen
  closeMenus(){
    console.log("Hello from the CloseMenus")
    if (window.innerWidth >= 1100) {
      this.activitiesListTarget.classList.add("d-none")
      this.infosListTarget.classList.add("d-none")
      console.log(this.activitiesListTarget.classList)
    } else {
      this.activitiesListTarget.classList.add("d-none")
      this.infosListTarget.classList.add("d-none")
      this.menuContainerTarget.classList.add("d-none")
    }
    // event.preventDefault()
  }
  // Dropdown menu toggle for responsive
  burgerMenu(){
    console.log("Welcome in the burger menu")
    this.menuContainerTarget.classList.toggle("d-none")
    event.preventDefault()
  }

  // Dropdown menu toogle
  dropdownActivities(){
    console.log("Hello from dropdownActivities controller")
    this.activitiesListTarget.classList.toggle("d-none")
    this.infosListTarget.classList.add("d-none")
    event.preventDefault()
  }

  dropdownInfos(){
    console.log("Hello from dropdownInfos controller")
    this.infosListTarget.classList.toggle("d-none")
    this.activitiesListTarget.classList.add("d-none")
    event.preventDefault()
  }

}
